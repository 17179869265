import { IconProps } from 'components/icons/types';

export const Behance = (props: IconProps) => {
  const { width = 18, height = 18, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.61783 8.1119C7.92939 7.62696 8.10729 7.04841 8.097 6.42813C8.06944 4.76827 6.68808 3.44922 5.02795 3.44922H0.393104C0.176018 3.44922 0 3.62524 0 3.84232V14.0349C0 14.252 0.176018 14.428 0.393104 14.428H5.57041C7.49199 14.428 9.08377 12.8878 9.09162 10.9663C9.09645 9.7886 8.51245 8.74451 7.61783 8.1119ZM2.03333 5.48255H5.06752C5.61701 5.48255 6.0641 5.9296 6.0641 6.47913C6.0641 7.02862 5.61705 7.47571 5.06752 7.47571H2.03333V5.48255ZM5.61556 12.3947H2.03333V9.50904H5.06752H5.61552C6.41107 9.50904 7.05833 10.1563 7.05833 10.9519C7.05833 11.7474 6.41111 12.3947 5.61556 12.3947Z"
        fill="currentColor" />
      <path
        d="M17.9999 10.2456C17.9999 7.87222 16.1293 5.94141 13.83 5.94141C11.5307 5.94141 9.66016 7.87226 9.66016 10.2456C9.66016 12.6189 11.5308 14.5498 13.83 14.5498C15.2252 14.5498 16.5216 13.8346 17.2979 12.6365C17.3947 12.4872 17.4826 12.3311 17.5606 12.1702C17.6173 12.0535 17.5314 11.9176 17.4016 11.9172L15.3518 11.9121C15.3064 11.912 15.2634 11.9306 15.2309 11.9622C14.8439 12.3385 14.3693 12.5164 13.83 12.5164C12.8114 12.5164 11.9574 11.7545 11.7448 10.7388H17.5919C17.8172 10.7388 17.9999 10.5561 17.9999 10.3307V10.2456ZM13.83 7.97474C14.5761 7.97474 15.2337 8.38359 15.616 9.00128H12.0441C12.4264 8.38359 13.084 7.97474 13.83 7.97474Z"
        fill="currentColor" />
      <path
        d="M15.5617 5.43556H11.8632C11.6907 5.43556 11.5508 5.29566 11.5508 5.1231V4.19527C11.5508 4.02271 11.6907 3.88281 11.8632 3.88281H15.5617C15.7343 3.88281 15.8742 4.02271 15.8742 4.19527V5.1231C15.8742 5.2957 15.7343 5.43556 15.5617 5.43556Z"
        fill="currentColor" />
    </svg>
  )
};
