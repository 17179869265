import { IconProps } from 'components/icons/types';

export const UpdateAvatarIcon = (props: IconProps) => {
  const { width = 31, height = 32, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 31 32" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_5881_130987" maskUnits="userSpaceOnUse" x="0" y="0" width="32"
            height="32">
        <rect x="0.628906" y="0.984375" width="30.3797" height="30.3797" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5881_130987)">
        <path
          d="M6.95743 27.5661C6.26122 27.5661 5.66523 27.3182 5.16945 26.8224C4.67367 26.3266 4.42578 25.7306 4.42578 25.0344V7.3129C4.42578 6.61669 4.67367 6.0207 5.16945 5.52492C5.66523 5.02914 6.26122 4.78125 6.95743 4.78125H15.8182C16.1768 4.78125 16.4775 4.90256 16.7201 5.14517C16.9627 5.38779 17.084 5.68842 17.084 6.04707C17.084 6.40572 16.9627 6.70636 16.7201 6.94897C16.4775 7.19159 16.1768 7.3129 15.8182 7.3129H6.95743V25.0344H24.6789V16.1737C24.6789 15.815 24.8003 15.5144 25.0429 15.2718C25.2855 15.0291 25.5861 14.9078 25.9448 14.9078C26.3034 14.9078 26.6041 15.0291 26.8467 15.2718C27.0893 15.5144 27.2106 15.815 27.2106 16.1737V25.0344C27.2106 25.7306 26.9627 26.3266 26.4669 26.8224C25.9711 27.3182 25.3752 27.5661 24.6789 27.5661H6.95743ZM8.22325 22.5028H23.4131L18.6663 16.1737L14.8688 21.2369L12.0207 17.4395L8.22325 22.5028ZM22.1473 9.84454H20.8815C20.5228 9.84454 20.2222 9.72323 19.9796 9.48062C19.737 9.238 19.6157 8.93737 19.6157 8.57872C19.6157 8.22007 19.737 7.91944 19.9796 7.67682C20.2222 7.4342 20.5228 7.3129 20.8815 7.3129H22.1473V6.04707C22.1473 5.68842 22.2686 5.38779 22.5112 5.14517C22.7538 4.90256 23.0545 4.78125 23.4131 4.78125C23.7718 4.78125 24.0724 4.90256 24.315 5.14517C24.5576 5.38779 24.6789 5.68842 24.6789 6.04707V7.3129H25.9448C26.3034 7.3129 26.6041 7.4342 26.8467 7.67682C27.0893 7.91944 27.2106 8.22007 27.2106 8.57872C27.2106 8.93737 27.0893 9.238 26.8467 9.48062C26.6041 9.72323 26.3034 9.84454 25.9448 9.84454H24.6789V11.1104C24.6789 11.469 24.5576 11.7696 24.315 12.0123C24.0724 12.2549 23.7718 12.3762 23.4131 12.3762C23.0545 12.3762 22.7538 12.2549 22.5112 12.0123C22.2686 11.7696 22.1473 11.469 22.1473 11.1104V9.84454Z"
          fill="currentColor" />
      </g>
    </svg>
  )
};
