import { IconProps } from 'components/icons/types';

export const X = (props: IconProps) => {
  const { width = 18, height = 18, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5881_131383)">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M11.9596 17.25L7.79752 11.3176L2.58714 17.25H0.382812L6.81955 9.92332L0.382812 0.75H6.04227L9.96498 6.34126L14.8799 0.75H17.0843L10.9462 7.73736L17.619 17.25H11.9596ZM14.4143 15.5775H12.9303L3.53907 2.4225H5.02332L8.78458 7.68987L9.435 8.6039L14.4143 15.5775Z"
              fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_5881_131383">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
};
