import { IconProps } from 'components/icons/types';

export const Github = (props: IconProps) => {
  const { width = 18, height = 18, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 18 18" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5881_131176)">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9 0C4.0293 0 0 4.0356 0 9.0153C0 12.9978 2.5785 16.3773 6.1551 17.5689C6.6051 17.6517 6.7689 17.3736 6.7689 17.1342C6.7689 16.9209 6.7617 16.353 6.7572 15.6015C4.2534 16.146 3.7251 14.3928 3.7251 14.3928C3.3165 13.3506 2.7261 13.0734 2.7261 13.0734C1.9089 12.5154 2.7882 12.5262 2.7882 12.5262C3.6909 12.5892 4.1661 13.455 4.1661 13.455C4.9689 14.832 6.273 14.4342 6.7851 14.2038C6.8679 13.6215 7.1001 13.2246 7.3575 12.9996C5.3595 12.7719 3.258 11.9979 3.258 8.5437C3.258 7.56 3.609 6.7545 4.1841 6.1245C4.0914 5.8968 3.7827 4.9797 4.2723 3.7395C4.2723 3.7395 5.0283 3.4965 6.7473 4.6629C7.48149 4.46266 8.23899 4.36067 9 4.3596C9.765 4.3632 10.5345 4.4631 11.2536 4.6629C12.9717 3.4965 13.7259 3.7386 13.7259 3.7386C14.2173 4.9797 13.9077 5.8968 13.8159 6.1245C14.3919 6.7545 14.7411 7.56 14.7411 8.5437C14.7411 12.0069 12.636 12.7692 10.6317 12.9924C10.9548 13.2705 11.2419 13.8204 11.2419 14.6619C11.2419 15.8661 11.2311 16.839 11.2311 17.1342C11.2311 17.3754 11.3931 17.6562 11.8503 17.568C13.6425 16.9669 15.2004 15.8178 16.3039 14.283C17.4075 12.7483 18.0008 10.9056 18 9.0153C18 4.0356 13.9698 0 9 0Z"
              fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_5881_131176">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
};
