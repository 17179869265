import { IconProps } from 'components/icons/types';

export const MagicSearchStarsIcon = (props: IconProps) => {
  const { width = 17, height = 18, className } = props;

  return (
    <svg className={className} width={width} height={height} viewBox="0 0 17 18" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.28822 2.39258L10.052 7.15918L14.8186 8.92298L10.052 10.6868L8.28822 15.4534L6.52441 10.6868L1.75781 8.92298L6.52441 7.15918L8.28822 2.39258Z"
        fill="currentColor" />
      <path
        d="M16.3672 15.5L14.5424 16.1752L13.8672 18L13.192 16.1752L11.3672 15.5L13.192 14.8248L13.8672 13L14.5424 14.8248L16.3672 15.5Z"
        fill="currentColor" />
      <path
        d="M5.36719 2.5L3.54241 3.17523L2.86719 5L2.19196 3.17523L0.367187 2.5L2.19196 1.82477L2.86719 -1.09278e-07L3.54241 1.82477L5.36719 2.5Z"
        fill="currentColor" />
    </svg>
  )
};
